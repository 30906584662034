import React from "react"
import Section from "../../library/section/section"
import { graphql, useStaticQuery } from "gatsby"
import SectionHeader from "../section-header/section-header"
import Gallery from "react-grid-gallery"
import galleryStyles from "./gallery.module.scss"
import ContactBanner from "../contact-banner/contact-banner"

function GalleryContent() {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
        nodes {
          id
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    }
  `)

  const IMAGE_ARRAY = data?.images.nodes?.map(node => {
    return {
      src: node?.childImageSharp?.fluid?.src,
      thumbnail: node?.childImageSharp?.fluid?.src,
      thumbnailWidth: 320,
    }
  })
  return (
    <div className={galleryStyles.container}>
      <Section>
        <SectionHeader header="Gallery" />
        <div className={galleryStyles.wrapper}>
          <Gallery
            images={IMAGE_ARRAY}
            enableLightbox={true}
            // maxRows={3}
            backdropClosesModal
            // currentImage={3}
            // isOpen={ true}
          />
        </div>
      </Section>
      <ContactBanner background="#f1f1f1" />
    </div>
  )
}

export default GalleryContent
