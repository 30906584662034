import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import GalleryContent from "../components/gallery/gallery-content"

function GalleryPage() {
  return (
    <Layout>
      <SEO title="Gallery" />
      <Hero
        title="Gallery"
        bgPosition="-1300px"
        image={
          "https://images.unsplash.com/photo-1532109666224-645794153808?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80"
        }
      ></Hero>

      <GalleryContent />
    </Layout>
  )
}

export default GalleryPage
